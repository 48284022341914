import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Рассчитываем значение для прозрачности и вертикального сдвига
  const opacity = Math.max(1 - scrollPosition / 500, 0);
  const translateY = Math.min(scrollPosition / 2, 100); // Плавный сдвиг контента вверх

  return (
    <div className="App">
      <div className="image-container">
        <img
          src={process.env.PUBLIC_URL + '/src/assets/notcat.jpg'}
          alt="Cute cat"
          className="fade-image"
          style={{ opacity: opacity }} // Прозрачность изображения
        />
      </div>
      <div 
        className="track-list"
        style={{
          transform: `translateY(${translateY}px)`, 
          opacity: 1 - opacity // Контент появляется по мере исчезновения изображения
        }}
      >
        <h1>Pidoras</h1>
        <ul>
          <li>Track 1</li>
          <li>Track 2</li>
          <li>Track 3</li>
          <li>Track 4</li>
          <li>Track 5</li>
        </ul>
      </div>
    </div>
  );
}

export default App;
